import { reducer, on } from "ts-action";
import { StoreWorkingHours } from "../../../../../../types/Manager/index";
import {
  getStoreWorkingHours,
  getStoreWorkingHoursFailure,
  getStoreWorkingHoursSuccess,
} from "../../../actions/Manager";

type IState = StoreWorkingHours & {
  isLoading: boolean;
  error: Error | null;
}

const initialState: IState = {
  delivery_intervals: [],
  pickup_intervals: [],
  isLoading: false,
  error: null,
};

export const getStoreWorkingHoursReducer = reducer<IState>(
  [
    on(getStoreWorkingHours, (state) => ({
      ...state,
      isLoading: true,
      error: null,
    })),
    on(getStoreWorkingHoursSuccess, (state, { payload }) => ({
      ...state,
      ...payload,
      isLoading: false,
      error: null,
    })),
    on(getStoreWorkingHoursFailure, (state, { payload }) => ({
      ...state,
      isLoading: false,
      error: payload,
    })),
  ],
  initialState
);
