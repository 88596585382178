import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import { deleteWorkingHoursScheduleAction } from "../../../../constants/actions";
import {
  deleteWorkingHoursScheduleFailure,
  deleteWorkingHoursScheduleSuccess,
  deleteWorkingHoursSchedule,
} from "../../../actions/Manager/Settings/workingHoursActions";
import { notify } from "react-notify-toast";
import { deleteWorkingHoursScheduleApi } from "../../../../axios/Manager";
import { union } from "ts-action";
import { IRootReducerState } from "../../../../../../redux-store/rootReducer";
import { WorkingHoursSchedule } from "../../../../../../types";
import { translate } from "../../../../../../helpers/translate";
import Strings from "../../../../i18n/strings/brandOpeningHours";

const t = translate("brandOpeningHours");
const actionTypes = union({ deleteWorkingHoursSchedule });
export function* deleteWorkingHoursScheduleSaga({
  payload,
}: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const WorkingHoursSchedules: WorkingHoursSchedule[] = yield select(
      (state: IRootReducerState) =>
        state.storeSettingsReduces.General.working_hours_schedules
    );

    const activatedWorkingHoursSchedule = WorkingHoursSchedules.find(
      (schedule) => schedule.is_active
    );

    yield call(deleteWorkingHoursScheduleApi, token, payload);

    yield put(deleteWorkingHoursScheduleSuccess(payload));
    notify.show(t(Strings.workingHoursScheduleDeletedSuccessfully), "success");
  } catch (error) {
    yield put(deleteWorkingHoursScheduleFailure(error as Error));
    notify.show(t(Strings.somethingWentWrong), "error");
  }
}

export function* watchDeleteWorkingHoursSchedule() {
  yield takeLatest(
    deleteWorkingHoursScheduleAction.requested,
    deleteWorkingHoursScheduleSaga
  );
}
