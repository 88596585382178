import { on, reducer } from "ts-action";
import { LoadingStatus } from "../../withLoadingState";
import {
  activateWorkingHoursSchedule,
  activateWorkingHoursScheduleFailure,
  activateWorkingHoursScheduleSuccess,
  createWorkingHoursSchedule,
  createWorkingHoursScheduleFailure,
  createWorkingHoursScheduleSuccess,
  deleteWorkingHoursSchedule,
  deleteWorkingHoursScheduleFailure,
  deleteWorkingHoursScheduleSuccess,
  resetWorkingHoursSchedule,
  selectWorkingHoursSchedule,
} from "../../../actions";
import { WorkingHoursSchedule } from "../../../../../../types";
import { getStoreSettingsSuccess } from "../../../actions/StoreSettings";

type WorkingHoursScheduleState = {
  status: LoadingStatus | undefined;
  workingHoursSchedules: WorkingHoursSchedule[];
  selectedWorkingHoursSchedule?: WorkingHoursSchedule;
  activeWorkingHoursSchedule: Partial<WorkingHoursSchedule>;
};

export default reducer<WorkingHoursScheduleState>(
  [
    on(getStoreSettingsSuccess, (state, { payload }) => ({
      ...state,
      workingHoursSchedules: payload.working_hours_schedules,
      activeWorkingHoursSchedule:
        payload.working_hours_schedules.find(
          (schedule) => schedule.is_active
        ) || {},
    })),
    on(selectWorkingHoursSchedule, (state, { payload }) => ({
      ...state,
      selectedWorkingHoursSchedule: state.workingHoursSchedules.find(
        (schedule) => schedule.id === payload
      ),
    })),
    on(createWorkingHoursSchedule, (state) => ({
      ...state,
      status: LoadingStatus.loading,
    })),
    on(createWorkingHoursScheduleSuccess, (state, { payload }) => ({
      ...state,
      status: LoadingStatus.success,
      workingHoursSchedules: [...state.workingHoursSchedules, payload],
      selectedWorkingHoursSchedule: payload,
    })),
    on(createWorkingHoursScheduleFailure, (state) => ({
      ...state,
      status: LoadingStatus.failed,
    })),

    on(deleteWorkingHoursSchedule, (state) => ({
      ...state,
      status: LoadingStatus.loading,
    })),
    on(deleteWorkingHoursScheduleSuccess, (state, { payload }) => ({
      ...state,
      status: LoadingStatus.success,
      workingHoursSchedules: state.workingHoursSchedules.filter(
        (schedule) => schedule.id !== payload
      ),
      selectedWorkingHoursSchedule: state.workingHoursSchedules.find(
        (schedule) => schedule.is_active
      ),
    })),
    on(deleteWorkingHoursScheduleFailure, (state) => ({
      ...state,
      status: LoadingStatus.failed,
    })),

    on(activateWorkingHoursSchedule, (state) => ({
      ...state,
      status: LoadingStatus.loading,
    })),
    on(activateWorkingHoursScheduleSuccess, (state, { payload }) => {
      const targetIndex = state.workingHoursSchedules.findIndex(
        (schedule) => payload === schedule.id
      );
      return {
        ...state,
        status: LoadingStatus.success,
        workingHoursSchedules: state.workingHoursSchedules.map((schedule) => {
          if (schedule.id === payload) {
            return {
              ...schedule,
              is_active: true,
            };
          }
          return schedule;
        }),
        activeWorkingHoursSchedule: {
          ...state.workingHoursSchedules[targetIndex],
          is_active: true,
        },
      };
    }),
    on(activateWorkingHoursScheduleFailure, (state) => ({
      ...state,
      status: LoadingStatus.failed,
    })),

    on(resetWorkingHoursSchedule, (state) => ({
      ...state,
      status: undefined,
    })),
  ],
  {
    status: undefined,
    workingHoursSchedules: [],
    selectedWorkingHoursSchedule: undefined,
    activeWorkingHoursSchedule: {},
  }
);
