import { call, put, select, takeLatest } from "redux-saga/effects";
import { selectToken } from "../../../../../../redux-store/selectors";
import { updateBranchesWorkingHoursAction } from "../../../../constants/actions";
import {
  updateBranchesWorkingHours,
  updateBranchesWorkingHoursFailure,
  updateBranchesWorkingHoursSuccess,
} from "../../../actions/Manager";
import { updateBranchesWorkingHoursApi } from "../../../../axios/Manager";
import { union } from "ts-action";
import { notify } from "react-notify-toast";

const actionTypes = union({ updateBranchesWorkingHours });

function* updateBranchesWorkingHoursSaga({ payload }: typeof actionTypes) {
  try {
    const token = yield select(selectToken);
    const res = yield call(updateBranchesWorkingHoursApi, token, payload);

    yield put(updateBranchesWorkingHoursSuccess(res));
    notify.show("Saved", "success");
  } catch (error) {
    yield put(updateBranchesWorkingHoursFailure(error as Error));
    notify.show("Failed to update store working hours!", "error");
  }
}

export function* watchUpdateBranchesWorkingHours() {
  yield takeLatest(
    updateBranchesWorkingHoursAction.requested,
    updateBranchesWorkingHoursSaga
  );
}
