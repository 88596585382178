import { StoreWorkingHours } from "../../../../../types";
import axios from "../../axios";
import * as Requests from "../../requests";

// get store working hours
export const getStoreWorkingHours = (token: string, scheduleId: string) => {
  return axios.get(Requests.WORKING_HOURS_SCHEDULE_API(scheduleId), {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};

// update store working hours
export const updateStoreWorkingHours = (
  token: string,
  payload: StoreWorkingHours
) => {
  return axios.put(Requests.updateStoreWorkingHours, payload, {
    headers: {
      token,
    },
    baseURL: process.env.PICKUP_ENDPOINTS,
  });
};
