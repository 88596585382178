import {
  getBranchWorkingHoursAction,
  getStoreWorkingHoursAction,
  updateStoreWorkingHoursAction,
  updateBranchesWorkingHoursAction,
  checkOnStoreWHUpdatingStatusAction,
  clearBranchesWorkingHoursAction,
  deleteWorkingHoursScheduleAction,
  activateWorkingHoursScheduleAction,
  createWorkingHoursScheduleAction,
  SELECT_WORKING_HOURS_SCHEDULE,
  RESET_WORKING_HOURS_SCHEDULE,
} from "../../../../constants/actions";
import { action, payload } from "ts-action";
import {
  CreateSchedulePayload,
  StoreWorkingHours,
  UpdateBranchesWorkingHoursPayload,
  WorkingHoursSchedule,
} from "../../../../../../types/Manager";

// get store working hours
export const getStoreWorkingHours = action(
  getStoreWorkingHoursAction.requested,
  payload<string>()
);
export const getStoreWorkingHoursSuccess = action(
  getStoreWorkingHoursAction.fulfilled,
  payload<StoreWorkingHours>()
);
export const getStoreWorkingHoursFailure = action(
  getStoreWorkingHoursAction.rejected,
  payload<Error>()
);

// update store working hours
export const updateStoreWorkingHours = action(
  updateStoreWorkingHoursAction.requested,
  payload<StoreWorkingHours>()
);
export const updateStoreWorkingHoursSuccess = action(
  updateStoreWorkingHoursAction.fulfilled,
  payload<any>()
);
export const updateStoreWorkingHoursFailure = action(
  getStoreWorkingHoursAction.rejected,
  payload<Error>()
);

// get branch working hours
export const getBranchWorkingHours = action(
  getBranchWorkingHoursAction.requested,
  payload<{
    branch_id: string;
    schedule_id: string;
  }>()
);
export const getBranchWorkingHoursSuccess = action(
  getBranchWorkingHoursAction.fulfilled,
  payload<{ [branch_id: string]: StoreWorkingHours }>()
);
export const getBranchWorkingHoursFailure = action(
  getBranchWorkingHoursAction.rejected,
  payload<Error>()
);

export const clearBranchesWorkingHours = action(
  clearBranchesWorkingHoursAction
);

// update branch working hours
export const updateBranchesWorkingHours = action(
  updateBranchesWorkingHoursAction.requested,
  payload<UpdateBranchesWorkingHoursPayload>()
);
export const updateBranchesWorkingHoursSuccess = action(
  updateBranchesWorkingHoursAction.fulfilled,
  payload<any>()
);
export const updateBranchesWorkingHoursFailure = action(
  updateBranchesWorkingHoursAction.rejected,
  payload<Error>()
);

// checkup on store updating store branches working hours status
export const checkOnStoreWHUpdatingStatus = action(
  checkOnStoreWHUpdatingStatusAction.requested
);
export const checkOnStoreWHUpdatingStatusSuccess = action(
  checkOnStoreWHUpdatingStatusAction.fulfilled,
  payload<any>()
);
export const checkOnStoreWHUpdatingStatusFailure = action(
  checkOnStoreWHUpdatingStatusAction.rejected,
  payload<Error>()
);

// Create new working hours schedule
export const createWorkingHoursSchedule = action(
  createWorkingHoursScheduleAction.requested,
  payload<CreateSchedulePayload>()
);

export const createWorkingHoursScheduleSuccess = action(
  createWorkingHoursScheduleAction.fulfilled,
  payload<WorkingHoursSchedule>()
);

export const createWorkingHoursScheduleFailure = action(
  createWorkingHoursScheduleAction.rejected,
  payload<Error>()
);
// select working hours schedule
export const selectWorkingHoursSchedule = action(
  SELECT_WORKING_HOURS_SCHEDULE,
  payload<string>()
);
// Delete working hours schedule
export const deleteWorkingHoursSchedule = action(
  deleteWorkingHoursScheduleAction.requested,
  payload<string>()
);
export const deleteWorkingHoursScheduleSuccess = action(
  deleteWorkingHoursScheduleAction.fulfilled,
  payload<string>()
);
export const deleteWorkingHoursScheduleFailure = action(
  deleteWorkingHoursScheduleAction.rejected,
  payload<Error>()
);

// Activate working hours schedule
export const activateWorkingHoursSchedule = action(
  activateWorkingHoursScheduleAction.requested,
  payload<string>()
);
export const activateWorkingHoursScheduleSuccess = action(
  activateWorkingHoursScheduleAction.fulfilled,
  payload<string>()
);
export const activateWorkingHoursScheduleFailure = action(
  activateWorkingHoursScheduleAction.rejected,
  payload<Error>()
);

export const resetWorkingHoursSchedule = action(RESET_WORKING_HOURS_SCHEDULE);
